import httpAuth from '../apiAuth';
import http from '../api';

let login = async (data) => {
  const response = await httpAuth.post('auth', data);
  return response;
};

let changePassword = (data, token) => {
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };
  return httpAuth.post('changepassword', data, config);
};

let getUser = () => {
  return http.get('current-adm');
};
let recoveryPassword = (data) => {
  return httpAuth.post('infopasswordrecovery', data);
};

let verifyHashRecoveryPassword = (data) => {
  return httpAuth.post('verifyhash', data);
};
let updatePassword = (data) => {
  return httpAuth.post('updatepassword', data);
};
let updateAuthUser = (user_id, data) => {
  return httpAuth.put(`users/${user_id}`, data);
};

let update = () => {};

export default {
  login,
  getUser,
  recoveryPassword,
  verifyHashRecoveryPassword,
  updatePassword,
  updateAuthUser,
  changePassword,
  update,
};
