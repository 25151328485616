import http from '../api';

let getMy = (data) => {
  return http.get(
    `members-tasks/?member_id=${data.member_id}&workspace_id=${data.workspace_id}&start_date=${data.start_date}&end_date=${data.end_date}`,
    data
  );
};

let getMyBySelect = (data) => {
  return http.get(
    `members-tasks/?member_id=${data.member_id}&workspace_id=${data.workspace_id}&start_date=${data.start_date}&end_date=${data.end_date}&type=${data.type}`
  );
};

let getAll = (data) => {
  return http.get(
    `tasks/?workspace_id=${data.workspace_id}&start_date=${data.start_date}&end_date=${data.end_date}&inactive=${data.inactive}`
  );
};

let save = (data) => {
  return http.post('tasks', data);
};

let modify = (data) => {
  return http.put(`tasks/${data.id}`, data);
};

let destroy = (data) => {
  return http.delete(`tasks/${data.id}`);
};

export default {
  getMy,
  getMyBySelect,
  save,
  modify,
  getAll,
  destroy,
};
