import http from '../api';
import Paginator from '../_paginator';

let getAll = (config) => {
  return new Paginator({
    url: 'suppliers',
    config: config,
  });
};
let store = (data) => {
  return http.post('suppliers', data);
};
let get = (data) => {
  return http.get(`suppliers/${data.params.id}`, data);
};

let check = (data) => {
  return http.get(
    `supplierCheck?id=${data.params.id}&workspace_id=${data.params.workspace_id}`
  );
};

let update = (data) => {
  return http.put(`suppliers/${data.id}`, data);
};

const del = (data) => {
  return http.delete(`suppliers?id=${data.id}`, data);
};

export default {
  getAll,
  store,
  get,
  update,
  check,
  del,
};
