<template>
  <div>
    <img
      :width="width"
      :src="require(`@/assets/images/logo/${imageUrl}`)"
      alt="logo-arch-web"
      class="text-center"
    />
  </div>
</template>

<script>
export default {
  name: 'vc-logo',
  props: {
    imageUrl: { type: String, default: '' },
    width: { type: Number, default: 100 },
  },
};
</script>
