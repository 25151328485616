import { projectCostsDao } from '@/service';

export default {
  state: {},
  mutations: {},
  actions: {
    async getAllProjectCosts(_, payload) {
      const config = {
        params: {
          perPage: 1000,
          workspace_id: payload.workspace_id,
          inactive: payload.inactive,
          group: payload.group,
        },
      };
      return new Promise((resolve, reject) => {
        projectCostsDao
          .getAll(config)
          .getPage()
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async saveProjectCosts(_, payload) {
      return new Promise((resolve, reject) => {
        projectCostsDao
          .store(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getProjectCosts(_, payload) {
      return new Promise((resolve, reject) => {
        projectCostsDao
          .get(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async updateProjectCosts(_, payload) {
      return new Promise((resolve, reject) => {
        projectCostsDao
          .update(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async removeProjectCosts(_, payload) {
      return new Promise((resolve, reject) => {
        projectCostsDao
          .deletee(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async activeCosts(_, payload) {
      return new Promise((resolve, reject) => {
        projectCostsDao
          .activateCosts(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getCalcCosts(_, payload) {
      return new Promise((resolve, reject) => {
        projectCostsDao
          .calc(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getInactivateProjectCosts(_, payload) {
      return new Promise((resolve, reject) => {
        projectCostsDao
          .getAll({ active: false, ...payload })
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },

  getters: {},
};
