import { depreciableAssetsDao } from '@/service';

export default {
  state: {},
  mutations: {},
  actions: {
    async getAllDepreciableAssets(_, payload) {
      const config = {
        params: {
          perPage: 1000,
          inactive: payload.inactive,
          workspace_id: payload.workspace_id,
        },
      };
      return new Promise((resolve, reject) => {
        depreciableAssetsDao
          .getAll(config)
          .getPage()
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async saveDepreciableAssets(_, payload) {
      return new Promise((resolve, reject) => {
        depreciableAssetsDao
          .store(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getDepreciableAssets(_, payload) {
      return new Promise((resolve, reject) => {
        depreciableAssetsDao
          .get(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async updateDepreciableAssets(_, payload) {
      return new Promise((resolve, reject) => {
        depreciableAssetsDao
          .update(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async inactivateDepreciableAssets(_, payload) {
      return new Promise((resolve, reject) => {
        depreciableAssetsDao
          .deletee(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async removeDepreciableAssets(_, payload) {
      return new Promise((resolve, reject) => {
        depreciableAssetsDao
          .deletee(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async activeDepreciableAssets(_, payload) {
      return new Promise((resolve, reject) => {
        depreciableAssetsDao
          .activateAssets(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getInactivesDepreciableAssets(_, payload) {
      return new Promise((resolve, reject) => {
        depreciableAssetsDao
          .getAll({ active: false, ...payload })
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },

  getters: {},
};
