import Vue from 'vue';
import { format } from 'date-fns';

Vue.mixin({
  methods: {
    dateFormatter(_date, formatString = 'dd/MM/yyyy') {
      let date = new Date(_date);
      if (date instanceof Date && !isNaN(date)) {
        return format(new Date(date), formatString);
      } else {
        return '';
      }
    },
    dateReferenceMonthFormatter(date, formatString = 'MM/yyyy') {
      if (date) {
        return format(new Date(date), formatString);
      } else {
        return ' - ';
      }
    },
    formatQueryParams(data) {
      let queryString = '';
      for (let [key, value] of Object.entries(data)) {
        if (value || value === false) {
          if (queryString === '') {
            queryString = `${queryString}?${key}=${value}`;
          } else {
            queryString = `${queryString}&${key}=${value}`;
          }
        }
      }
      return queryString;
    },

    signalNumber(num) {
      if (num > 0) {
        return true;
      } else if (num < 0) {
        return false;
      }
    },
    reverseDateString(date) {
      return date.split('-').reverse().join('-');
    },

    currencyFormatter(currency) {
      if (currency) {
        return currency.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        });
      } else {
        return 'R$ 0,00';
      }
    },

    numberToMonetary(number) {
      let negative = number < 0;
      if (negative) {
        number = number * -1;
      }

      if (isNaN(number)) {
        return 'Invalid input';
      }
      if (number === 0) {
        return '0,00';
      }
      if (number < 100) {
        const formattedCents = number;
        return `${negative ? '-' : ''}0,${formattedCents}`;
      }
      const [integerPart, decimalPart] = (number / 100)
        .toFixed(2)
        .toString()
        .split('.');
      const formattedIntegerPart = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ','
      );
      const result = `${
        negative ? '-' : ''
      }${formattedIntegerPart},${decimalPart}`;
      return result;
    },

    monetaryToNumber(monetaryString) {
      const removeDot = monetaryString.replace('.', '');

      const numericString = removeDot.replace(/[^\d,-]/g, '');
      console.log({ numericString });

      const numberString = numericString.replace(',', '.');
      console.log({ numberString });

      const result = parseFloat(numberString) * 100;
      console.log({ result });

      console.log({ final: Math.round(result * 100) / 100 });
      return Math.round(result * 100) / 100;
    },

    monetaryOnlyCommas(monetaryString) {
      let noCommaOrPointNumber = monetaryString.replace(/[,.]/g, '');
      let converted = parseFloat(noCommaOrPointNumber);
      console.log({ converted });
      return converted;
    },

    convertCurrencyToNumber(currencyString) {
      const cleanedString = currencyString.replace(/[^\d.,]/g, '');
      const numberString = cleanedString.replace(',', '.');
      const numberValue = parseFloat(numberString);
      return isNaN(numberValue) ? null : numberValue;
    },

    mascaraPhone(data) {
      var countString = data;
      if (countString) {
        if (countString.length == 11) {
          const parte1 = data.slice(0, 2);
          const parte2 = data.slice(2, 3);
          const parte3 = data.slice(3, 7);
          const parte4 = data.slice(7, 11);
          return (data = ` (${parte1}) ${parte2} ${parte3}-${parte4}`);
        } else {
          const parte1 = data.slice(0, 2);
          const parte2 = data.slice(2, 6);
          const parte3 = data.slice(6, 10);
          return (data = ` (${parte1}) ${parte2}-${parte3}`);
        }
      }
    },

    convertStringToNumber(str) {
      const cleanedStr = str.replace(/[^0-9,.]/g, '');
      const formattedStr = cleanedStr.replace(',', '.');
      const number = parseFloat(formattedStr, 10);
      return number;
    },

    isAdmin() {
      let verify = false;
      if (this.$store.getters.user) {
        const role = this.$store.getters.user.company_user.role;
        if (role.slug === 'admin') {
          verify = true;
        } else {
          this.$router.push({ name: 'error-404' });
        }
      }
      return verify;
    },
    isAdminOrDoctor() {
      let verify = false;
      if (this.$store.getters.user) {
        const role = this.$store.getters.user.company_user.role;
        if (role.slug === 'admin' || this.$store.getters.user.people.doctor) {
          verify = true;
        } else {
          this.$router.push({ name: 'error-404' });
        }
      }
      return verify;
    },
    // percentageFormatter(data) {
    //     return data.toString().replace('.', ',') + '%'
    // },

    // subString(text, size) {
    //     if (text.length > size)
    //         return text.substr(0, size) + '...'
    //     else
    //         return text
    // },

    onlyHours(dataString) {
      // Criar um objeto de data a partir da string fornecida
      const data = new Date(dataString);
      data.setHours(data.getHours() + 3);

      // Obter as horas e minutos da data
      const horas = data.getHours();
      const minutos = data.getMinutes();

      // Formatar as horas e minutos para o formato desejado (com zero à esquerda, se necessário)
      const horasFormatadas = horas < 10 ? '0' + horas : horas;
      const minutosFormatados = minutos < 10 ? '0' + minutos : minutos;

      // Retornar a string no formato "hh:mm"
      return `${horasFormatadas}:${minutosFormatados}`;
    },
    maskCpf(valor) {
      if (valor && valor.length === 11)
        //eslint-disable-line
        //eslint-disable-next-line
        return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
    },

    mascaraCnpj(valor) {
      //eslint-disable-line
      //eslint-disable-next-line
      return valor.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        '$1.$2.$3/$4-$5'
      );
    },
  },
});
