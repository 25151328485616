import http from '../api';
import httpAuth from '../apiAuth';
import Paginator from '../_paginator';

let getAll = (config) => {
  return new Paginator({
    url: 'users',
    config: config,
  });
};
let store = (data) => {
  return http.post('users', data);
};
let get = (data) => {
  return http.get(`users/${data.id}`, data);
};
let update = (data) => {
  return http.put(`users/${data.id}`, data);
};

let deletee = (data) => {
  return http.delete(`users/${data.id}`, data);
};

let storeAuth = (data) => {
  return httpAuth.post('new-user', data);
};
let activateUser = (data) => {
  return http.put(`activate-user/${data.id}`, data);
};

let getAllUsersNotVinculated = (data) => {
  return http.get(`users-not-vinculated/${data.id}`);
};
let storeUserPermision = (data) => {
  return http.post('save-user-permision', data);
};

let storeEmployee = (data) => {
  return http.post('user-with-workspace', data);
};

let dashboard = (data) => {
  return http.get(`users-dashboard/${data.id}`, { params: data });
};

let generalDashboard = (data) => {
  return http.get(
    `dashboard/?start_date=${data.start_date}&end_date=${data.end_date}&workspace_id=${data.workspace_id}`
  );
};

let userMember = (data) => {
  return http.get(
    `user-member/?id=${data.id}&workspace_id=${data.workspace_id}`
  );
};

let getExportFile = (data) => {
  console.log('export file', data);
  return http.get(
    `files?collaborators=${data.collaborators}&projects=${data.projects}&tasks=${data.tasks}&financial=${data.financial}&suppliers=${data.suppliers}&goods=${data.goods}&workspace_id=${data.workspace_id}`,
    data
  );
};

let downloadFile = (data) => {
  return http.get(`files/${data.fileName}`);
};

export default {
  getAll,
  userMember,
  generalDashboard,
  getExportFile,
  downloadFile,
  store,
  get,
  update,
  deletee,
  storeAuth,
  activateUser,
  getAllUsersNotVinculated,
  storeUserPermision,
  dashboard,
  storeEmployee,
};
