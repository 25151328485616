import http from '../api';
// import Paginator from '../_paginator';

let getAll = async (config) => {
  const { workspace_id } = config;

  const response = await http.get(`revenues/?workspace_id=${workspace_id}`, {
    workspace_id: config.workspace_id,
  });
  return response;
};

let getOne = (data) => {
  return http.get(`revenues/${data.id}`);
};

let store = (data) => {
  return http.post('revenues', data);
};

let update = async (data) => {
  const response = await http.put(`revenues/${data.id}`, data);
  return response;
};

let destroyy = async (data) => {
  const response = await http.delete(`revenues/${data}`);
  return response;
};

let activate = async (data) => {
  const response = await http.put(`revenues-reactive?id=${data.id}`);
  return response;
};

export default {
  getAll,
  getOne,
  store,
  update,
  activate,
  destroyy,
};
