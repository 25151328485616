import http from '../api';
import Paginator from '../_paginator';

let getAll = (config) => {
  return new Paginator({
    url: 'expenses-categories',
    config: config,
  });
};

let getOne = (data) => {
  return http.get(`expense-category/${data.params.id}`);
};

let store = (data) => {
  return http.post('expenses-categories', data);
};

let update = async (data) => {
  const response = await http.put(`expenses-categories?id=${data.id}`, data);
  return response;
};

let deletee = async (data) => {
  const response = await http.delete(`expenses-categories?id=${data}`);
  return response;
};

let activate = async (data) => {
  const response = await http.put(`expense-category-reactive?id=${data.id}`);
  return response;
};

export default {
  getAll,
  getOne,
  store,
  update,
  activate,
  deletee,
};
