import { revenueDao } from '@/service';

export default {
  state: {},
  mutations: {},
  actions: {
    async getAllRevenues(_, payload) {
      return new Promise((resolve, reject) => {
        revenueDao
          .getAll(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async getRevenue(_, payload) {
      return new Promise((resolve, reject) => {
        revenueDao
          .getOne(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async updateRevenue(_, payload) {
      return new Promise((resolve, reject) => {
        revenueDao
          .update(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async saveRevenue(_, payload) {
      return new Promise((resolve, reject) => {
        revenueDao
          .store(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async destroyRevenue(_, payload) {
      return new Promise((resolve, reject) => {
        revenueDao
          .destroyy(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },

  getters: {},
};
