import Vue from 'vue';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';

import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask);

import VMoney from 'v-money2';
Vue.use(VMoney, {
  moneyMask: {
    prefix: 'R$ ',
    suffix: '',
    thousands: '.',
    decimal: ',',
    precision: 2,
    masked: false,
  },
});

import { BSpinner } from 'bootstrap-vue';
Vue.component('b-spinner', BSpinner);
import router from './router';
import store from './store';
import App from './App.vue';

// Global Components
import './global-components';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect);
// 3rd party plugins
import '@/libs/portal-vue';
import '@/libs/toastification';

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

import VueScheduler from 'v-calendar-scheduler';
import 'v-calendar-scheduler/lib/main.css';
Vue.use(VueScheduler, {
  locale: 'pt-BR',
  minDate: null,
  maxDate: null,
  labels: {
    today: 'Hojê',
    back: 'Anterior',
    next: 'Seguinte',
    month: 'Mês',
    week: 'Semana',
    day: 'Dia',
    all_day: 'Todos os dias',
  },
  timeRange: [11, 20],
  availableViews: ['weekly', 'day', 'month'],
  initialDate: new Date(),
  initialView: 'week',
  use12: false,
  showTimeMarker: true,
  showTodayButton: false,
  eventDisplay: null,
});

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require('@core/scss/core.scss');
// Mixin
import '@/mixin/mixinShared.js';
export const eventBus = new Vue();
// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;

store.dispatch('initAuth').then(() =>
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app')
);
