import { supplierDao } from '@/service';

export default {
  state: {},
  mutations: {},
  actions: {
    async getAllSuppliers(_, payload) {
      const config = {
        params: {
          perPage: 1000,
          workspace_id: payload.workspace_id,
        },
      };
      return new Promise((resolve, reject) => {
        supplierDao
          .getAll(config)
          .getPage()
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getSupplier(_, payload) {
      const config = {
        params: {
          id: payload.id,
        },
      };

      return new Promise((resolve, reject) => {
        supplierDao
          .get(config)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async checkSupplierVinculate(_, payload) {
      const config = {
        params: {
          id: payload.id,
          workspace_id: payload.workspace_id,
        },
      };

      return new Promise((resolve, reject) => {
        supplierDao
          .check(config)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async saveSupplier(_, payload) {
      return new Promise((resolve, reject) => {
        supplierDao
          .store(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async updateSupplier(_, payload) {
      return new Promise((resolve, reject) => {
        supplierDao
          .update(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async deleteSupplier(_, payload) {
      return new Promise((resolve, reject) => {
        supplierDao
          .del(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {},
};
