import { taxesDao } from '@/service';

export default {
  state: {},
  mutations: {},
  actions: {
    async getAllTaxes(_, payload) {
      const config = {
        params: {
          perPage: 1000,
          inactive: payload.inactive,
          workspace_id: payload.workspace_id,
        },
      };
      return new Promise((resolve, reject) => {
        taxesDao
          .getAll(config)
          .getPage()
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async saveTaxes(_, payload) {
      return new Promise((resolve, reject) => {
        taxesDao
          .store(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getTax(_, payload) {
      return new Promise((resolve, reject) => {
        taxesDao
          .get(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getInactive(_, payload) {
      return new Promise((resolve, reject) => {
        taxesDao
          .getAllInactive(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async updateTaxes(_, payload) {
      return new Promise((resolve, reject) => {
        taxesDao
          .update(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async removeTaxes(_, payload) {
      return new Promise((resolve, reject) => {
        taxesDao
          .deletee(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async activeTaxes(_, payload) {
      return new Promise((resolve, reject) => {
        taxesDao
          .update(payload)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async getInactivesTaxes(_, payload) {
      return new Promise((resolve, reject) => {
        taxesDao
          .getAll({ active: false, ...payload })
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },

  getters: {},
};
